
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";

export default Vue.extend({
  name: "OfflineForms",
  data: () => ({
    isFormValid: true,
    isLoading: true,
    tempFileList: [] as string[],
    isSnacking: false,
    snackColor: 'info',
    snackMessage: '',
    snackTimeout: 1500,
    showSnackBtn: false,
  }),
  computed: {
    fileList() {
      return this.tempFileList.map(filename => ({ filename }));
    },
    headers() {
      return [
        {
          text: i18n.t("offlineforms.table.filename"),
          value: "filename",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async fetchDownloadableFiles() {
      this.isLoading = true;
      await axios
        .get('survey/download/list').then((res) => {
            this.tempFileList = res.data;
            this.isLoading = false;
        })
        .catch(() => {
            this.isLoading = false;
        });
    },
    downloadItem(filename: string) {
      axios
        .get('survey/download', {
          params: {
            filename: filename,
          },
          responseType: 'arraybuffer'
        }).then((res) => {
            let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
        })
        .catch((err) => {
            this.snackColor = 'error'
            this.snackMessage = this.$t('offlineforms.fileunavailable').toString()
            this.snackTimeout = -1
            this.showSnackBtn = true
            this.isSnacking = true
            
            this.fetchDownloadableFiles();
        });
    },
  },
  created() {
    this.fetchDownloadableFiles();
  },
});
